// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import styles from './Menu.module.scss';
// BJM https://flow.org/en/docs/types/aliases/
type Props = {
  menu: {
    label: string,
    path: string
  }[]
};

//BJM abandoning Flow's type definitions for now
class Menu extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    }
    //this.toggleMenu = this.toggleMenu.bind(this);

  }

  /*toggleMenu(event) {
    console.log('this is working');
    this.setState(
      {showMenu: !this.state.showMenu}
    );
  }*/

  toggleMenu = (event) => { 
      this.setState(
        {showMenu: !this.state.showMenu}
      );
    } 

  render() {
    return(
      <nav className={`${styles['menu']} absolute md:relative right-0`}>

{/*https://css-tricks.com/snippets/svg/svg-hamburger-menu/*/}
        <svg viewBox="0 0 100 80" width="40" height="40" onClick={this.toggleMenu} className="md:hidden absolute right-2 top-2 bg-white bg-opacity-50 opacity-50">
            <rect width="100" height="15" rx="8"></rect>
            <rect y="30" width="100" height="15" rx="8"></rect>
            <rect y="60" width="100" height="15" rx="8"></rect>
        </svg>

        <ul className={`${styles['menu__list']} ${this.state.showMenu ? '':'hidden'} md:block transition-all p-8 pl-8 pt-1 mt-12 md:p-0 md:m-0 bg-white bg-opacity-95`}>
          {this.props.menu.map((item) => (
            <li className={styles['menu__list-item']} key={item.path}>
              <Link
                to={item.path}
                className={styles['menu__list-item-link']}
                activeClassName={styles['menu__list-item-link--active']}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    );
  }


}

//BJM original functional component
/*const Menu = ({ menu }: Props) => (
  <nav className={styles['menu']}>
    <span className="block">Toggle</span>
    <ul className={`${styles['menu__list']} hidden`}>
      {menu.map((item) => (
        <li className={styles['menu__list-item']} key={item.path}>
          <Link
            to={item.path}
            className={styles['menu__list-item-link']}
            activeClassName={styles['menu__list-item-link--active']}
          >
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  </nav>
);*/

export default Menu;
